import AdvText from "@components/data/text";
import { AdvButtonPure } from "@components/inputs/button/button-pure";
import AdvTextInput from "@components/inputs/text-input";
import AdvModal, { TAdvModalProps } from "@components/layout/modal";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import AdvLoading, { EAdvLoadingMode, EAdvLoadingPosition } from "@components/other/loading";
import { EAdvSpinnerSize } from "@components/other/spinner";
import { LAN } from "@data/language/strings";
import useAdvToast from "@hooks/dialogs/useAdvToast";
import { useT } from "@hooks/language/useTranslation";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useAdvLogin } from "@hooks/useAdvLogin";
import useAdvTheme from "@hooks/useAdvTheme";
import { KeyIcon } from "@themes/icons";
import { useMemo, useState } from "react";

export type TPasswordResetModalProps = Pick<TAdvModalProps, "isOpen" | "styles"> & {
    onClosed(AResetted: boolean): void;
    resetToken: string; // falls token über die url kommt
    resetEmail: string;
};

type TPasswordValidation = {
    HasLowerCaseLetters: boolean;
    HasUpperCaseLetters: boolean;
    HasSpecialLetters: boolean;
    HasNumbers: boolean;
    FulfillsLength: boolean;
};

/** Prüft, ob das übergebene Password unseren Richtlinien entspricht. */
function validatePassword(password: string): TPasswordValidation {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialLetters = /[!#$%&? "]/g;

    return {
        HasLowerCaseLetters: password.match(lowerCaseLetters) != null,
        HasUpperCaseLetters: password.match(upperCaseLetters) != null,
        HasSpecialLetters: password.match(specialLetters) != null,
        HasNumbers: password.match(numbers) != null,
        FulfillsLength: password.length >= 8 && password.length <= 24,
    };
}

const PasswordResetModal = ({
    resetToken: urlResetToken,
    resetEmail: urlResetEmail,
    onClosed,
    ...props
}: TPasswordResetModalProps) => {
    const { resetPassword } = useAdvLogin();

    const { showSuccess, showError } = useAdvToast({ position: "bottom-right" });

    const [isInitiated, setInitiated] = useState<boolean>(false); // Wurde eine Email rausgeschickt?
    const [isResetted, setResetted] = useState<boolean>(false); // Erfolgreich zurückgesetzt?

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    const { t: translatedErrorMessage } = useT(errorMessage);

    const [usernameOrEmail, setUsernameOrEmail] = useState("");
    const [resetToken, setResetToken] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const passwordValidation = useMemo(() => validatePassword(newPassword), [newPassword]);
    const isPasswordValid = useMemo(
        () => Object.values(passwordValidation).indexOf(false) == -1,
        [passwordValidation],
    );

    useAdvEffect(() => {
        showError(translatedErrorMessage, undefined, 4000);
    }, [showError, translatedErrorMessage]);

    useAdvEffect(() => {
        setResetToken(urlResetToken);
        if (urlResetToken !== "") setInitiated(true);
        else setInitiated(false);
    }, [urlResetToken]);

    useAdvEffect(() => {
        setUsernameOrEmail(urlResetEmail);
        if (urlResetEmail !== "") setInitiated(true);
        else setInitiated(false);
    }, [urlResetEmail]);

    useAdvEffect(() => {
        if (props.isOpen == false) {
            // Aufräumen
            setErrorMessage(undefined);

            setUsernameOrEmail("");
            setNewPassword("");
            setResetToken("");

            setInitiated(false);
            setResetted(false);
            setLoading(false);
        }
    }, [props.isOpen]);

    const handleDismiss = () => {
        onClosed(false);
    };

    const handleReset = async () => {
        setLoading(true);
        setErrorMessage(undefined);
        try {
            if (isInitiated == false) {
                if (usernameOrEmail == "") {
                    setErrorMessage(LAN.RESET_PASSWORD_ERROR_EMPTY.text);
                    return;
                }

                const wasInitiaionSuccessful = await resetPassword(usernameOrEmail);
                if (wasInitiaionSuccessful == false) {
                    setErrorMessage(LAN.RESET_PASSWORD_ERROR_NAME.text);
                    return;
                } else {
                    setInitiated(true); // Email wurde verschickt, jetzt warten wir auf den Code
                    showSuccess(LAN.RESET_EMAIL_SENT.text);
                }
            } else {
                if (newPassword == "" || resetToken == "") {
                    setErrorMessage(LAN.RESET_PASSWORD_ERROR_EMPTY.text);
                    return;
                }

                const wasResetSuccessful = await resetPassword(
                    usernameOrEmail,
                    resetToken,
                    newPassword,
                );
                if (wasResetSuccessful == false) {
                    setErrorMessage(LAN.RESET_PASSWORD_ERROR_TOKEN.text);
                    return;
                } else {
                    setResetted(true);
                    showSuccess(LAN.RESET_PASSWORD_SUCCESS.text);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    /**
     * Wenn der Benutzer bereits einen Token hat, dann muss er trotzdem noch seine E-Mail Adresse angeben.
     * Entspricht also der normalen Logik, somit können wir hier ebenfalls handleReset nutzen.
     * Mails werden nur maximal alle 5 Minuten verschickt, der Server liefert uns dennoch ein Success.
     */
    const handleResetWithToken = handleReset;

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key.toLowerCase() == "enter") {
            void handleReset();
        } else if (event.key.toLowerCase() == "escape") {
            handleDismiss();
        }
    };

    const theme = useAdvTheme();

    return (
        <AdvModal
            isModeless={true}
            isDarkOverlay={true}
            onDismiss={handleDismiss}
            headline={LAN.RESET_HEADER.text}
            {...props}
        >
            <AdvStack verticalFill verticalAlign="space-between">
                <AdvStackItem>
                    <AdvTextInput
                        key="usernameOrEmail"
                        label={LAN.USERNAME_OR_EMAIL.text}
                        required
                        autoFocus
                        value={usernameOrEmail}
                        onValueChanged={(value) => setUsernameOrEmail(value ?? "")}
                        disabled={isLoading || isInitiated}
                        onKeyDown={handleKeyDown}
                        errorMessage={isInitiated == false ? translatedErrorMessage ?? "" : ""}
                    />
                    {isInitiated && <AdvText>{LAN.RESET_EMAIL_SENT.text}</AdvText>}
                    {isInitiated && (
                        <AdvTextInput
                            key="resetToken"
                            label={LAN.RESET_TOKEN.text}
                            required
                            value={resetToken}
                            onValueChanged={(value) => setResetToken(value ?? "")}
                            disabled={isLoading || isResetted}
                        />
                    )}
                    {isInitiated && (
                        <AdvTextInput
                            key="newPassword"
                            label={LAN.NEW_PASSWORD.text}
                            required
                            value={newPassword}
                            onValueChanged={(value) => setNewPassword(value ?? "")}
                            disabled={isLoading || isResetted}
                            type="password"
                            canRevealPassword
                            errorMessage={isInitiated ? translatedErrorMessage ?? "" : ""}
                        />
                    )}
                    {isInitiated && !isResetted && (
                        <>
                            <AdvText block label={`${LAN.PASSWORD_GUIDELINE.text}:`} />
                            <AdvText
                                block
                                styles={{
                                    root: {
                                        color: passwordValidation.FulfillsLength
                                            ? undefined
                                            : theme.palette.orange,
                                    },
                                }}
                                label={`- ${LAN.PASSWORD_GUIDELINE_LEN.text}`}
                            />
                            <AdvText
                                block
                                styles={{
                                    root: {
                                        color:
                                            passwordValidation.HasLowerCaseLetters &&
                                            passwordValidation.HasUpperCaseLetters
                                                ? undefined
                                                : theme.palette.orange,
                                    },
                                }}
                                label={`- ${LAN.PASSWORD_GUIDELINE_CASE.text}`}
                            />
                            <AdvText
                                block
                                styles={{
                                    root: {
                                        color: passwordValidation.HasNumbers
                                            ? undefined
                                            : theme.palette.orange,
                                    },
                                }}
                                label={`- ${LAN.PASSWORD_GUIDELINE_NMBER.text}`}
                            />
                            <AdvText
                                block
                                styles={{
                                    root: {
                                        color: passwordValidation.HasSpecialLetters
                                            ? undefined
                                            : theme.palette.orange,
                                    },
                                }}
                                label={`- ${LAN.PASSWORD_GUIDELINE_SPCL.text}`}
                            />
                        </>
                    )}
                    {isInitiated && isResetted && <AdvText>{LAN.RESET_DONE.text}</AdvText>}
                </AdvStackItem>
                <AdvStackItem>
                    <AdvStack horizontal style={{ justifyContent: "space-between" }}>
                        <AdvStackItem>
                            {isResetted == false ? (
                                <AdvButtonPure
                                    key="close"
                                    text={LAN.CANCEL.text}
                                    onClick={handleDismiss}
                                />
                            ) : (
                                <AdvButtonPure
                                    key="close"
                                    text={LAN.CONTINUE.text}
                                    onClick={handleDismiss}
                                    primary
                                />
                            )}
                        </AdvStackItem>
                        {isResetted == false && isInitiated == false ? (
                            <AdvStackItem>
                                <AdvButtonPure
                                    iconProps={KeyIcon}
                                    key="key"
                                    text={LAN.RESET_USING_TOKEN.text}
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onClick={handleResetWithToken}
                                    disabled={
                                        isLoading || (isInitiated && isPasswordValid == false)
                                    }
                                />
                            </AdvStackItem>
                        ) : null}
                        {isLoading ? (
                            <AdvStackItem>
                                <AdvLoading
                                    isLoading={isLoading}
                                    spinnerProps={{ size: EAdvSpinnerSize.large }}
                                    mode={EAdvLoadingMode.ShowLoadingAtPosition}
                                    position={EAdvLoadingPosition.BeforeContent}
                                />
                            </AdvStackItem>
                        ) : null}
                        {isResetted == false ? (
                            <AdvStackItem>
                                <AdvButtonPure
                                    key="resetPassword"
                                    text={LAN.RESET_PASSWORD.text}
                                    primary
                                    disabled={
                                        isLoading || (isInitiated && isPasswordValid == false)
                                    }
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onClick={handleReset}
                                />
                            </AdvStackItem>
                        ) : null}
                    </AdvStack>
                </AdvStackItem>
            </AdvStack>
        </AdvModal>
    );
};

export default PasswordResetModal;
